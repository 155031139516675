export enum LOCAL_STORAGE_KEYS {
  REFERRER_ID = "referrerId",
}
export const useLocalStorage = (): {
  set: (key: LOCAL_STORAGE_KEYS, value: string) => void;
  get: (key: LOCAL_STORAGE_KEYS) => string | null;
  remove: (key: LOCAL_STORAGE_KEYS) => void;
  clear: () => void;
} => {
  const set = (key: LOCAL_STORAGE_KEYS, value: string) => {
    localStorage.setItem(key, value);
  };
  const get = (key: string) => {
    return localStorage.getItem(key);
  };
  const remove = (key: LOCAL_STORAGE_KEYS) => {
    localStorage.removeItem(key);
  };
  const clear = () => {
    localStorage.clear();
  };
  return { set, get, remove, clear };
};
