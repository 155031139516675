import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { JpdCompanyAddressPage } from "./JpdCompanyAddressPage";
import { CompanyDataRoutesProps } from "./types";
import { JpdAddressDetailsPage } from "./JpdAddressDetailsPage";
import { JpdCompanyDataPage } from "./JpdCompanyDataPage";
import { JpdOperatoryDataPage } from "./JpdOperatoryDataPage";
import { JpdFundsOriginPage } from "./JpdFundsOriginPage";
import { JpdCompanyNamePage } from "./JpdCompanyNamePage";
import { JuridicalPersonDataForm } from "@letsbit/malcolmlb";
import { JpdConfirmPage } from "./JpdConfirmPage";
import { CompanyRepresentativePage } from "./CompanyRepresentativePage";

export const CompanyDataRoutes: React.FC<CompanyDataRoutesProps> = ({
  onSubmit: onSubmitCompanyData,
  form: companyForm,
}) => {
  const navigate = useNavigate();
  const onSubmit = (url: string) => (form: object) => {
    Object.entries(form).forEach(([name, value]) =>
      companyForm.setValue(name as keyof JuridicalPersonDataForm, value)
    );

    navigate(url);
  };

  const values = companyForm.getValues();

  return (
    <Routes>
      <Route
        path="/company-name"
        element={
          <JpdCompanyNamePage onSubmit={onSubmit("./company-address")} />
        }
      />
      <Route
        path="/company-address"
        element={
          <JpdCompanyAddressPage onSubmit={onSubmit("./address-details")} />
        }
      />
      <Route
        path="/company-representative"
        element={
          <CompanyRepresentativePage onSubmit={onSubmit("./company-address")} />
        }
      />
      <Route
        path="/address-details"
        element={
          <JpdAddressDetailsPage
            locationForm={{
              street_name: values.street_name,
              street_number: values.street_number,
              postcode: values.postcode,
              city: values.city,
              province: values.province,
            }}
            onSubmit={onSubmit("./company-data")}
          />
        }
      />
      <Route
        path="/company-data"
        element={<JpdCompanyDataPage onSubmit={onSubmit("./operatory-data")} />}
      />
      <Route
        path="/operatory-data"
        element={<JpdOperatoryDataPage onSubmit={onSubmit("./funds-origin")} />}
      />
      <Route
        path="/funds-origin"
        element={<JpdFundsOriginPage onSubmit={onSubmit("./confirmation")} />}
      />
      <Route
        path="/confirmation"
        element={
          <JpdConfirmPage onSubmit={onSubmitCompanyData} companyData={values} />
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to="/logged/onboarding/foreign/company-data/national-id"
            replace={true}
          />
        }
      />
    </Routes>
  );
};
