import React from "react";
import { ReactComponent as PeopleImage } from "assets/images/onboarding/customer_error_people.svg";
import { FormattedMessage } from "react-intl";
import NotebookImage from "assets/images/onboarding/customer_error_notebook.png";
import { messages } from "./messages";

export const SolutionContent: React.FC = () => {
  return (
    <div className="flex h-full flex-col items-end justify-center text-center">
      <div className="relative flex items-center justify-center">
        <img
          className="absolute left-1/2 top-[280px] -translate-x-1/2 -translate-y-1/2 transform"
          src={NotebookImage}
        />
        <PeopleImage />
      </div>
      <h2 className="mb-8 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-white">
        <FormattedMessage {...messages.PeopleImage} />
      </h2>
      <p className="text-base text-white">
        <FormattedMessage
          {...messages.solutionSubtitle}
          values={{
            br: <br />,
          }}
        />
      </p>
    </div>
  );
};
