import React, { createContext } from "react";
import { MemberBoundary, PublicBoundary } from "@letsbit/malcolmlb";
import { LogInAuthentication } from "@letsbit/malcolmlb/lib/LogInAuthentication";
import { ServiceEnv } from "@letsbit/malcolmlb/lib/interfaces/services.types";
import { createVoidContext } from "utils/voidContext";

interface ApiContextType {
  memberInstance: MemberBoundary;
  publicInstance: PublicBoundary;
}

export const ApiContext = createContext<ApiContextType>(
  createVoidContext("api-context")
);

export const ApiProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const env = window.env.apiEnv || "test";
  const malcolmlbUrl = window.env.malcolmlbUrl;

  const config = {
    env: env as ServiceEnv,
    version: "v1",
    url: malcolmlbUrl ? malcolmlbUrl : undefined,
  };

  const authenticationApp = new LogInAuthentication({
    ...config,
    withCredentials: true,
  });

  const memberInstance = new MemberBoundary(authenticationApp);
  const publicInstance = new PublicBoundary({
    ...config,
  });

  return (
    <ApiContext.Provider
      value={{
        publicInstance,
        memberInstance,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
