import React from "react";
import ReactDOM from "react-dom/client";
import { IntercomProvider } from "react-use-intercom";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { IonApp } from "@ionic/react";
import { Auth0Provider } from "@auth0/auth0-react";

import { ApiProvider } from "./providers/ApiProvider";
import LocaleProvider from "./providers/LocaleProvider";
import { UserProvider } from "./providers/UserProvider";
import LocationProvider from "./providers/LocationProvider";
import NotificationsProvider from "./providers/NotificationsProvider";

import App from "./App";

import ARMessages from "./compiled-lang/es-AR.json";

import "./assets/styles/global.scss";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <IntlProvider
        locale={"es-AR"}
        defaultLocale="es-AR"
        messages={ARMessages}
      >
        <NotificationsProvider>
          <LocationProvider>
            <LocaleProvider>
              <IntercomProvider
                appId={window.env.intercomAppId}
                autoBoot
                autoBootProps={{
                  alignment: "right",
                }}
              >
                <ApiProvider>
                  <Auth0Provider
                    domain={window.env.auth0_domain}
                    clientId={window.env.auth0_client_id}
                  >
                    <UserProvider>
                      <IonApp className="ion-root">
                        <App />
                      </IonApp>
                    </UserProvider>
                  </Auth0Provider>
                </ApiProvider>
              </IntercomProvider>
            </LocaleProvider>
          </LocationProvider>
        </NotificationsProvider>
      </IntlProvider>
    </BrowserRouter>
  </React.StrictMode>
);
