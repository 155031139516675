import classNames from "classnames";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { UserContext } from "providers/UserProvider";
import { useIntercom } from "react-use-intercom";

import { NavBarButtonProps } from "./types";

import { ReactComponent as ChatIcon } from "assets/images/icons/navbar/chat.svg";
import { ReactComponent as HelpIcon } from "assets/images/icons/navbar/help.svg";
import { ReactComponent as LogoutIcon } from "assets/images/icons/navbar/logout.svg";
import { ReactComponent as DepositIcon } from "assets/images/icons/navbar/deposit.svg";
import { ReactComponent as DollarizeIcon } from "assets/images/icons/navbar/dollarize.svg";
import { ReactComponent as HomeIcon } from "assets/images/icons/navbar/home.svg";
import { ReactComponent as MultipleIcon } from "assets/images/icons/navbar/multiple.svg";
import { ReactComponent as ProfileIcon } from "assets/images/icons/navbar/profile.svg";
import { ReactComponent as SwapIcon } from "assets/images/icons/navbar/swap.svg";
import { ReactComponent as TransferIcon } from "assets/images/icons/navbar/transfer.svg";
import { ReactComponent as LogoIcon } from "assets/images/icons/navbar/logo.svg";
import { ReactComponent as WalletIcon } from "assets/images/icons/navbar/wallet.svg";
import { messages } from "./messages";
import { LocationContext } from "providers/LocationProvider";
import { useGetUserCountry } from "hooks/user/useGetUserCountry";

const NavBarButton: React.FC<NavBarButtonProps> = ({
  icon,
  children,
  active,
  color = "--milkylb-color-secondary-100",
  ...props
}) => {
  return (
    <button
      className={classNames(
        `flex w-full items-center rounded-lg px-2 py-1 transition-all`,
        !active && "hover:bg-[--color-navbar-hover]"
      )}
      style={{
        color: `var(${color})`,
      }}
      {...props}
    >
      <span
        className={classNames(
          "mr-4 p-2",
          active && "rounded-full bg-[--color-alternative-accent]"
        )}
      >
        {icon}
      </span>
      {children}
    </button>
  );
};

export const NavBar: React.FC = () => {
  const { logout } = useContext(UserContext);
  const { show } = useIntercom();
  const { location } = useContext(LocationContext);
  const country = useGetUserCountry()

  return (
    <nav
      className={classNames(
        "fadeIn z-10 flex w-[calc((100vw - 60px) / 12)] min-w-[237px] flex-col justify-between bg-[--milkylb-color-secondary-800] px-6 py-8",
        !location.pathname.startsWith("/logged") && "fadeOut"
      )}
    >
      <div className="flex flex-col gap-1">
        <div className="mb-11 flex h-14 w-14 items-center justify-center rounded-full bg-[--color-navbar-logo]">
          <LogoIcon className="ml-1" />
        </div>
        <Link to="/logged/dashboard">
          <NavBarButton
            icon={<HomeIcon />}
            active={location.pathname.startsWith("/logged/dashboard")}
          >
            <FormattedMessage {...messages.homeLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged/balance">
          <NavBarButton
            active={location.pathname.startsWith("/logged/balance")}
            icon={<WalletIcon />}
          >
            <FormattedMessage {...messages.balanceLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged/exchange">
          <NavBarButton
            active={location.pathname.startsWith("/logged/exchange")}
            icon={<SwapIcon />}
          >
            <FormattedMessage {...messages.swapLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged/deposit">
          <NavBarButton
            active={location.pathname.startsWith("/logged/deposit")}
            icon={<DepositIcon />}
          >
            <FormattedMessage {...messages.depositLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged/withdraw">
          <NavBarButton
            active={location.pathname.startsWith("/logged/withdraw")}
            icon={<TransferIcon />}
          >
            <FormattedMessage {...messages.withdrawLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged/multiples-withdraw">
          <NavBarButton
            active={location.pathname.startsWith("/logged/multiples-withdraw")}
            icon={<MultipleIcon />}
          >
            <FormattedMessage {...messages.multipleLabel} />
          </NavBarButton>
        </Link>
        {country === "AR" &&
          <Link to="/logged/dollarize">
            <NavBarButton
              active={location.pathname.startsWith("/logged/dollarize")}
              icon={<DollarizeIcon />}
            >
              <FormattedMessage {...messages.dollarizeLabel} />
            </NavBarButton>
          </Link>
        }
        <Link to="/logged/profile">
          <NavBarButton
            active={location.pathname.startsWith("/logged/profile")}
            icon={<ProfileIcon />}
          >
            <FormattedMessage {...messages.profileLabel} />
          </NavBarButton>
        </Link>
      </div>
      <div className="flex flex-col gap-1">
        <a href="https://help.letsbit.io/" target="_blank">
          <NavBarButton
            icon={<HelpIcon />}
            color="--milkylb-color-secondary-200"
          >
            <FormattedMessage {...messages.helpCenterLabel} />
          </NavBarButton>
        </a>
        <NavBarButton
          icon={<ChatIcon />}
          onClick={() => show()}
          color="--milkylb-color-secondary-200"
        >
          <FormattedMessage {...messages.contactLabel} />
        </NavBarButton>
        <NavBarButton
          icon={<LogoutIcon />}
          onClick={() => logout()}
          color="--milkylb-color-red-error"
        >
          <FormattedMessage {...messages.logOutLabel} />
        </NavBarButton>
      </div>
    </nav>
  );
};
