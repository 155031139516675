import { defineMessages } from "react-intl";

export const scope = "pages.OnBoardingForeign.ExtraSwornDeclarationUploadPage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Declaraciones juradas",
  },
  sendButton: {
    id: `${scope}.sendButton`,
    defaultMessage: "ENVIAR",
  },
});
