import { Input } from "@letsbit/milkylb";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { ReactComponent as PinLocationIcon } from "assets/images/icons/pin-location.svg";

import { messages } from "./messages";
import { LocationFormProps } from "./types";
import classNames from "classnames";
import { usePlaceDetails } from "hooks/utils/usePlaceDetails";
import usePlacesAutocomplete from "use-places-autocomplete";
import ItemArrow from "components/ItemArrow";
import { useGetUserCountry } from "hooks/user/useGetUserCountry";

export const LocationForm: React.FC<LocationFormProps> = ({
  onSubmit,
  className,
}) => {
  const { formatMessage } = useIntl();

  const { getPlaceDetails } = usePlaceDetails();
  const country = useGetUserCountry();

  const {
    value: inputValue,
    setValue,
    suggestions: { status, data },
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: country?.toLowerCase() || "ar",
      },
    },
    // ms delay before making a request to Google Maps Places API
    debounce: 500,
  });

  return (
    <form
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center"
      )}
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="h-full w-full">
        <Input
          onIonInput={(e) => {
            setValue(e.target.value as string);
          }}
          value={inputValue}
          placeholder={formatMessage(messages.companyPlaceholder)}
          type="text"
          inputmode="text"
          autocomplete="off"
        />
        <div>
          {status === "ZERO_RESULTS" && (
            <div className="legal-address-prediction__hint">
              <FormattedMessage
                {...messages.emptyResults}
                values={{
                  bold: <b>{formatMessage(messages.emptyResultsBold)}</b>,
                  br: <br />
                }}
              />
            </div>
          )}
          {status === "OK" &&
            data.length > 0 &&
            data.map((p: google.maps.places.AutocompletePrediction) => (
              <ItemArrow
                onClick={async () => {
                  const placeDetails = await getPlaceDetails(
                    formatMessage(messages.cantGetPlaceDetails),
                    p.place_id
                  );

                  if (placeDetails) {
                    onSubmit({
                      street_name: placeDetails.street_name,
                      street_number: placeDetails.street_number,
                      postcode: placeDetails.postcode,
                      city: placeDetails.city,
                      province: placeDetails.province,
                    });
                  }
                }}
                key={p.place_id}
                className="legal-address-prediction__item__arrow"
              >
                <div style={{ display: "flex" }}>
                  <PinLocationIcon />
                  <div className="legal-address-prediction__item__text">
                    {p.description}
                  </div>
                </div>
              </ItemArrow>
            ))}
        </div>
      </div>
    </form>
  );
};
