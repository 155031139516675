import {
  Button,
  DateTimeSelector,
  Input,
  MultiLineInput,
} from "@letsbit/milkylb";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { messages } from "./messages";
import { CompanyDataFormProps, CompanyDataForm as CompForm } from "./types";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { formMessages } from "utils/formMessages";

export const CompanyDataForm: React.FC<CompanyDataFormProps> = ({
  onSubmit,
  className,
}) => {
  const { handleSubmit, control } = useForm<CompForm>({
    mode: "onBlur",
  });
  const { formatMessage } = useIntl();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center"
      )}
    >
      <div className="h-full w-full">
        <Controller
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.activityLabel} />
              </label>
              <MultiLineInput
                placeholder={formatMessage(messages.activityPlaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                messageError={error?.message}
              />
            </div>
          )}
          name="activity"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.inscriptionDateLabel} />
              </label>
              <DateTimeSelector
                floatingLabel=""
                onChange={onChange}
                value={value}
                message={error?.message}
                error={!!error}
              />
            </div>
          )}
          name="registration_date"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.registrationNumberLabel} />
              </label>
              <Input
                placeholder={formatMessage(
                  messages.registrationNumberPlaceholder
                )}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name="registration_number"
        />
      </div>
      <Button
        type="submit"
        style={{ width: 197 }}
        className="mt-8 w-full self-end"
      >
        <FormattedMessage {...messages.continueButton} />
      </Button>
    </form>
  );
};
