import { defineMessages } from "react-intl";

export const scope = "components.OnBoardingLocal.StepsPage";

export const messages = defineMessages({
  start: {
    id: `${scope}.start`,
    defaultMessage: "EMPEZAR",
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: "Completemos tu cuenta",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: "Para avanzar necesitaremos que tengas a mano documentación de tu empresa y que cargues documentación acerca de su compañía.",
  },
  step1Title: {
    id: `${scope}.step1Title`,
    defaultMessage: "Número de identificación tributaria",
  },
  step2Title: {
    id: `${scope}.step2Title`,
    defaultMessage: "Representante legal",
  },
  step3Title: {
    id: `${scope}.step3Title`,
    defaultMessage: "Datos de la compañía",
  },
  step3Desc1: {
    id: `${scope}.step3Desc1`,
    defaultMessage: "Razón social y Domicilio",
  },
  step3Desc2: {
    id: `${scope}.step3Desc2`,
    defaultMessage: "Fecha y número de inscripción Registral",
  },
  step3Desc3: {
    id: `${scope}.step3Desc3`,
    defaultMessage: "Estatuto o contrato social",
  },
  step3Desc4: {
    id: `${scope}.step3Desc4`,
    defaultMessage: "Acta de asamblea de designación de autoridades",
  },
  step4Title: {
    id: `${scope}.step4Title`,
    defaultMessage: "Documentación de la compañía",
  },
  step4Desc1: {
    id: `${scope}.step4Desc1`,
    defaultMessage: "Estatuto social",
  },
  step4Desc2: {
    id: `${scope}.step4Desc2`,
    defaultMessage: "Acta de asamblea",
  },
  step4Desc3: {
    id: `${scope}.step4Desc3`,
    defaultMessage: "Origen de fondos",
  },
  step4Desc4: {
    id: `${scope}.step4Desc4`,
    defaultMessage: "Información para la elaboración de una declaración jurada {br} sobre Sujeto Obligado y Control directo e indirecto.",
  },
});
