import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { messages } from "./messages";
import { ExtraDocumentsPageProps } from "../types";
import { LogOutButton } from "components/LogOutButton";
import { ExtraDocsCard } from "../../../components/ExtraDocsCard";

export const ExtraDocumentsPage: React.FC<ExtraDocumentsPageProps> = ({
  companyExtract,
  sourceOfFounds,
  swornDeclaration,
  directorPassport,
}) => {
  const navigate = useNavigate();

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center">
      <LogOutButton className="absolute right-[40px] top-[24px]" />
      <div className="flex h-full w-full flex-col p-12">
        <div className="mb-8 flex items-center justify-between border-b border-[--milkylb-color-secondary-200] pb-8">
          <h1 className="w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-800]">
            <FormattedMessage {...messages.title} />
          </h1>
        </div>
        <div className="flex gap-6">
          <ExtraDocsCard
            onClick={() => navigate("./company-extract")}
            extraDocument={companyExtract}
            label={<FormattedMessage {...messages.companyExtractLabel} />}
          />
          <ExtraDocsCard
            onClick={() => navigate("./director-passport")}
            extraDocument={directorPassport}
            label={
              <FormattedMessage
                {...messages.directorPassportLabel}
                values={{
                  br: <br />,
                }}
              />
            }
          />
          <ExtraDocsCard
            onClick={() => navigate("./source-of-founds")}
            extraDocument={sourceOfFounds}
            label={
              <FormattedMessage
                {...messages.fundsOriginLabel}
                values={{
                  br: <br />,
                }}
              />
            }
          />
          <ExtraDocsCard
            onClick={() => navigate("./sworn-declaration")}
            icon="sign"
            extraDocument={swornDeclaration}
            label={<FormattedMessage {...messages.swornDeclarationsLabel} />}
          />
        </div>
      </div>
    </div>
  );
};
