import { Button, Error, MultiLineInput } from "@letsbit/milkylb";
import React, { useRef } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import { ReactComponent as UploadIcon } from "assets/images/icons/upload.svg";
import { messages } from "./messages";
import { FundsOriginUploadProps } from "./types";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { formMessages } from "utils/formMessages";
import FileDropzone from "components/FileDropzone";
import { DropzoneRef } from "react-dropzone";
import { USER_DOCUMENTATION_TYPE } from "@letsbit/malcolmlb";

export const FundsOriginUpload: React.FC<FundsOriginUploadProps> = ({
  onSubmit,
  formId,
  className,
  children,
}) => {
  const dropzoneRef = useRef<DropzoneRef>(null);
  const { handleSubmit, control } = useForm<{
    files: File[];
    comments: string;
  }>({
    mode: "onBlur",
  });
  const { formatMessage } = useIntl();

  return (
    <form
      id={formId}
      onSubmit={handleSubmit((data) =>
        onSubmit({
          files: data.files,
          reason: "onboarding_pj",
          upload_type: USER_DOCUMENTATION_TYPE.SOURCE_OF_FUNDS,
          comments: data.comments,
        })
      )}
      className={classNames(
        className,
        "fadeInSimple flex h-full w-full gap-10"
      )}
    >
      <div className="flex-1">
        <p className="mb-10 text-[--milkylb-color-neutral-600]">
          <FormattedMessage {...messages.description} />
        </p>
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.commentLabel} />
              </label>
              <MultiLineInput
                placeholder={formatMessage(messages.commentplaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                messageError={error?.message}
              />
            </div>
          )}
          name="comments"
        />
        {children}
      </div>
      <Controller
        control={control}
        rules={{
          required: formatMessage(formMessages.requiredFieldError),
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className="flex-1">
            <div className="h-full">
              <FileDropzone
                acceptedExtensions={["application/pdf"]}
                files={value}
                onChange={(files) => onChange(files)}
                dropzoneRef={dropzoneRef}
                noClick
              >
                <div className="flex w-80 flex-col items-center justify-items-center">
                  <UploadIcon className="mb-4" />
                  <h6 className="mb-10 font-['Tomato_Grotesk'] text-lg text-[--milkylb-color-neutral-200]">
                    <FormattedMessage {...messages.dropzonePlaceholder} />
                  </h6>
                  <Button
                    className="mb-2"
                    leftIcon={ArrowIcon}
                    onClick={() => dropzoneRef.current?.open()}
                  >
                    <FormattedMessage {...messages.uploadButton} />
                  </Button>
                </div>
              </FileDropzone>
            </div>
            <Error error={!!error?.message} message={error?.message} />
          </div>
        )}
        name="files"
      />
    </form>
  );
};
