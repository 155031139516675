import { defineMessages } from "react-intl";

export const scope = "components.NavBar";

export const messages = defineMessages({
  withdrawLabel: {
    id: `${scope}.withdrawLabel`,
    defaultMessage: "Enviar",
  },
  swapLabel: {
    id: `${scope}.swapLabel`,
    defaultMessage: "Convertir",
  },
  depositLabel: {
    id: `${scope}.depositLabel`,
    defaultMessage: "Recibir",
  },
  homeLabel: {
    id: `${scope}.homeLabel`,
    defaultMessage: "Inicio",
  },
  balanceLabel: {
    id: `${scope}.balanceLabel`,
    defaultMessage: "Balance",
  },
  multipleLabel: {
    id: `${scope}.multipleLabel`,
    defaultMessage: "Envío múltiple",
  },
  dollarizeLabel: {
    id: `${scope}.dollarizeLabel`,
    defaultMessage: "Dolarizar",
  },
  profileLabel: {
    id: `${scope}.profileLabel`,
    defaultMessage: "Perfil",
  },
  helpCenterLabel: {
    id: `${scope}.helpCenterLabel`,
    defaultMessage: "Centro de ayuda",
  },
  contactLabel: {
    id: `${scope}.contactLabel`,
    defaultMessage: "Atención al cliente",
  },
  logOutLabel: {
    id: `${scope}.logOutLabel`,
    defaultMessage: "Cerrar sesión",
  },
});
