import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.MinOfMeatingUpload";

export const messages = defineMessages({
  description: {
    id: `${scope}.description`,
    defaultMessage:
      "Necesitamos que cargues el Acta de asamblea de designación de autoridades de tu compañía para poder validar tu cuenta en Let’sBit. Si tu compañía es una Sociedad de Responsabilidad Limitada, podes subir el estatuto de la misma.",
  },
  dropzonePlaceholder: {
    id: `${scope}.dropzonePlaceholder`,
    defaultMessage: "Arrastra tu archivos .pdf aqui",
  },
  uploadButton: {
    id: `${scope}.uploadButton`,
    defaultMessage: "CARGAR ARCHIVO",
  },
});
