import { Route, Routes } from "react-router-dom";
import { OnBoardingLocalCompaniesRoutes } from "./OnBoardingLocalCompaniesRoutes";
import { OnBoardingErrorRoutes } from "./OnBoardingErrorRoutes";
import { OnBoardingForeignCompaniesRoutes } from "./OnBoardingForeignCompaniesRoutes";

export const OnBoardingRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/foreign/*" element={<OnBoardingForeignCompaniesRoutes />} />
      <Route path="/local/*" element={<OnBoardingLocalCompaniesRoutes />} />
      <Route path="/error/*" element={<OnBoardingErrorRoutes />} />
    </Routes>
  );
};
