import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.ExtraDocsCard";

export const messages = defineMessages({
  uploadLabel: {
    id: `${scope}.uploadLabel`,
    defaultMessage: "Enviado",
  },
  rejectedLabel: {
    id: `${scope}.rejectedLabel`,
    defaultMessage: "Rechazado",
  },
  aprovedLabel: {
    id: `${scope}.aprovedLabel`,
    defaultMessage: "Aprobado",
  },
});
