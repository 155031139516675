import React from "react";
import { FormattedMessage } from "react-intl";
import { Navigate } from "react-router-dom";

import { messages } from "./messages";
import { JpdAddressDetailsPageProps } from "../types";
import { AddressDetailsForm } from "../../../components/forms/AddressDetailsForm";
import { LogOutButton } from "components/LogOutButton";
import { BackButton } from "components/BackButton";

export const JpdAddressDetailsPage: React.FC<JpdAddressDetailsPageProps> = ({
  onSubmit,
  locationForm,
}) => {
  if (!locationForm) {
    return <Navigate to="../company-address" replace={true} />;
  }

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center">
      <BackButton className="absolute left-[40px] top-[24px]" />

      <LogOutButton className="absolute right-[40px] top-[24px]" />
      <div className="flex h-full flex-col py-[5%]">
        <h1 className="mb-8 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
          <FormattedMessage {...messages.title} />
        </h1>
        <h2 className="mb-8 w-full font-['Tomato_Grotesk'] text-2xl font-bold text-[--milkylb-color-neutral-600]">
          {locationForm.street_name} {locationForm.street_number} <br />
          {locationForm.city}, {locationForm.province}
        </h2>
        <AddressDetailsForm className="w-[448px] grow" onSubmit={onSubmit} />
      </div>
    </div>
  );
};
