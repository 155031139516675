import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { StepsPage } from "./StepsPage";
import { CompanyNationalIdPage } from "./CompanyNationalIdPage";
import { CompanyRepresentativePage } from "./CompanyRepresentativePage";
import { JuridicalPersonDataRoutes } from "./JuridicalPersonDataRoutes";
import { useForm } from "react-hook-form";
import {
  CustomerData,
  JuridicalPersonDataForm,
  VERIFICATION_ENTERPRISE_STEPS,
} from "@letsbit/malcolmlb";
import { RepresentativeForm } from "../components/forms/RepresentativeForm/types";
import { useCreateCustomer } from "hooks/user/useCreateCustomer";
import LoadingAnimation from "components/LoadingAnimation";
import { useCreateJuridicalPersonData } from "hooks/user/useCreateJuridicalPersonData";
import { useEffect } from "react";
import { useGetOnBoardingSteps } from "hooks/user/useGetOnBoardingSteps";
import { StepsCompletition } from "./types";
import { errorParser } from "utils/defaultError";
import { ExtraDocumentsRoutes } from "./ExtraDocumentsRoutes";
import { SuccessPage } from "./SuccessPage";

export const OnBoardingLocalCompaniesRoutes: React.FC = () => {
  const navigate = useNavigate();

  const jpdForm = useForm<JuridicalPersonDataForm>({});
  const { loading: creatingCustomer, createCustomer } = useCreateCustomer();
  const { loading: creatingJPD, createJuridicalPersonData } =
    useCreateJuridicalPersonData();
  const { data, isLoading: loadingSteps, mutate } = useGetOnBoardingSteps();

  useEffect(() => {
    if (data?.step === VERIFICATION_ENTERPRISE_STEPS.ENDED) {
      navigate("/logged/dashboard");
    }
  });

  const completed: StepsCompletition = {
    customer_data: !!data?.customer,
    director_email: !!(
      data?.jpd ||
      (jpdForm.getValues().director_member_email &&
        jpdForm.getValues().director_name)
    ),
    juridical_person_data: !!data?.jpd,
    extra_docs: data?.step === VERIFICATION_ENTERPRISE_STEPS.ENDED,
  };

  const onContinue = () => {
    switch (data?.step) {
      case VERIFICATION_ENTERPRISE_STEPS.CUSTOMER_DATA:
        navigate("./company-national-id");
        break;
      case VERIFICATION_ENTERPRISE_STEPS.JURIDICAL_DATA:
        if (
          jpdForm.getValues().director_member_email &&
          jpdForm.getValues().director_name
        ) {
          navigate("./juridical-person-data/company-name");
        } else {
          navigate("./company-representative");
        }
        break;
      case VERIFICATION_ENTERPRISE_STEPS.EXTRA_DOCS:
        navigate("./extra-documents");
        break;
      default:
        break;
    }
  };

  const onSubmitCustomerData = async (form: CustomerData) => {
    try {
      await createCustomer(form);

      navigate("/logged/onboarding/local");
    } catch (error) {
      switch (errorParser(error)) {
        case "member.customer.customer_already_taken":
          navigate("/logged/onboarding/error/already-registered-customer");
          break;
        default:
          break;
      }
    }
  };

  const onSubmitRepresentativeEmail = (form: RepresentativeForm) => {
    jpdForm.setValue("director_member_email", form.director_member_email);
    jpdForm.setValue("director_name", form.director_name);

    navigate("/logged/onboarding/local");
  };

  const onSubmitJPD = jpdForm.handleSubmit(async () => {
    await createJuridicalPersonData(jpdForm.getValues());
    navigate("/logged/onboarding/local");
  });

  return (
    <>
      {(creatingCustomer || creatingJPD || loadingSteps) && (
        <LoadingAnimation />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <StepsPage
              reloadSteps={mutate}
              completed={completed}
              onContinue={onContinue}
            />
          }
        />
        <Route path="/success" element={<SuccessPage />} />
        <Route
          path="/company-national-id"
          element={<CompanyNationalIdPage onSubmit={onSubmitCustomerData} />}
        />
        <Route
          path="/company-representative"
          element={
            <CompanyRepresentativePage onSubmit={onSubmitRepresentativeEmail} />
          }
        />
        <Route
          path="/juridical-person-data/*"
          element={
            <JuridicalPersonDataRoutes form={jpdForm} onSubmit={onSubmitJPD} />
          }
        />
        <Route
          path="/extra-documents/*"
          element={
            <ExtraDocumentsRoutes
              reloadSteps={mutate}
              juridicalPersonData={data?.jpd}
              extraDocuments={data?.extra_docs}
            />
          }
        />
        <Route
          path="*"
          element={<Navigate to="/logged/onboarding/local" replace={true} />}
        />
      </Routes>
    </>
  );
};
