import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.CompanyExtractUpload";

export const messages = defineMessages({
  description: {
    id: `${scope}.description`,
    defaultMessage:
      "Necesitamos que cargues el estatuto o contrato social de tu compañía para poder validar el usuario.",
  },
  dropzonePlaceholder: {
    id: `${scope}.dropzonePlaceholder`,
    defaultMessage: "Arrastra tu archivos .pdf aqui",
  },
  uploadButton: {
    id: `${scope}.uploadButton`,
    defaultMessage: "CARGAR ARCHIVO",
  },
});
