import { Button, Input } from "@letsbit/milkylb";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as WarningIcon } from "assets/images/icons/warning.svg";

import { messages } from "./messages";
import { useGetNationalIdTypes } from "hooks/compliance/useGetNationalIdTypes";
import { PERSON_TYPES } from "@letsbit/malcolmlb";
import LoadingAnimation from "components/LoadingAnimation";
import { CustomerForm, JuridicalCustomerFormProps } from "./types";
import classNames from "classnames";
import { formMessages } from "utils/formMessages";

export const JuridicalCustomerForm: React.FC<JuridicalCustomerFormProps> = ({
  onSubmit,
  country,
  className,
}) => {
  const { handleSubmit, control } = useForm<CustomerForm>({
    mode: "onBlur",
  });
  const { formatMessage } = useIntl();

  const { data: nationalIdtypes } = useGetNationalIdTypes({
    country,
    person_type: PERSON_TYPES.JURIDICAL,
  });
  const nit = nationalIdtypes?.[0];

  return nit ? (
    <form
      onSubmit={handleSubmit(({ national_id }) => {
        onSubmit({
          country,
          national_id_type: nit.type,
          national_id,
          person_type: PERSON_TYPES.JURIDICAL,
        });
      })}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center"
      )}
    >
      <div className="h-full">
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage
                  {...messages.inputDescriptionNationalIdType}
                  values={{
                    national_id_type: nit.type.toUpperCase(),
                  }}
                />
              </label>
              <Input
                placeholder={formatMessage(
                  messages.companyPlaceholderNationalIdType,
                  {
                    national_id_type: nit.type.toUpperCase(),
                  }
                )}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name="national_id"
        />
        <div
          className={
            "flex items-center text-xs font-semibold text-[--milkylb-color-neutral-400]"
          }
        >
          <WarningIcon className="shrink-0" />{" "}
          <p className="ml-2">
            <FormattedMessage {...messages.warning} />
          </p>
        </div>
      </div>
      <Button
        type="submit"
        style={{ width: 197 }}
        className="mt-8 w-full self-end"
      >
        <FormattedMessage {...messages.continueButton} />
      </Button>
    </form>
  ) : (
    <LoadingAnimation />
  );
};
