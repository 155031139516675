import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ExtraDocumentsRoutesProps } from "./types";
import { ExtraDocumentsPage } from "./ExtraDocumentsPage";
import { useEffect, useMemo } from "react";
import {
  FormExtraDocumentation,
  USER_DOCUMENTATION_TYPE,
} from "@letsbit/malcolmlb";
import { ExtraOriginOfFundsUploadPage } from "./ExtraOriginOfFundsUploadPage";
import {
  extraDocHasValidState,
  useUploadExtraDataDocument,
} from "hooks/user/useUploadExtraDataDocument";
import LoadingAnimation from "components/LoadingAnimation";
import { ExtraCompanyExtractUploadPage } from "./ExtraCompanyExtractUploadPage";
import { ExtraPassportUploadPage } from "./ExtraPassportUploadPage";
import { ExtraSwornDeclarationUploadPage } from "./ExtraSwornDeclarationUploadPage";

export const ExtraDocumentsRoutes: React.FC<ExtraDocumentsRoutesProps> = ({
  extraDocuments,
  juridicalPersonData,
  reloadSteps,
}) => {
  const navigate = useNavigate();

  const sortedExtraDocuments = useMemo(() => {
    return extraDocuments?.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
  }, [extraDocuments]);

  const companyExtract = sortedExtraDocuments?.find(
    (e) => e.upload_type === USER_DOCUMENTATION_TYPE.COMPANY_EXTRACT
  );
  const sourceOfFounds = sortedExtraDocuments?.find(
    (e) => e.upload_type === USER_DOCUMENTATION_TYPE.SOURCE_OF_FUNDS
  );
  const swornDeclaration = sortedExtraDocuments?.find(
    (e) => e.upload_type === USER_DOCUMENTATION_TYPE.SWORN_DECLARATION
  );
  const directorPassport = sortedExtraDocuments?.find(
    (e) => e.upload_type === USER_DOCUMENTATION_TYPE.DIRECTOR_PASSPORT
  );
  const { uploadExtraDocument, loading } = useUploadExtraDataDocument();

  const onSubmit = async (form: FormExtraDocumentation) => {
    await uploadExtraDocument(form);
    navigate("./");
    reloadSteps();
  };

  useEffect(() => {
    if (
      extraDocHasValidState(companyExtract) &&
      extraDocHasValidState(sourceOfFounds) &&
      extraDocHasValidState(swornDeclaration) &&
      extraDocHasValidState(directorPassport)
    ) {
      navigate("../success");
    }
  }, [
    companyExtract,
    directorPassport,
    navigate,
    sourceOfFounds,
    swornDeclaration,
  ]);

  return (
    <>
      {loading && <LoadingAnimation />}
      <Routes>
        <Route
          path="/"
          element={
            <ExtraDocumentsPage
              companyExtract={companyExtract}
              sourceOfFounds={sourceOfFounds}
              swornDeclaration={swornDeclaration}
              directorPassport={directorPassport}
            />
          }
        />
        <Route
          path="/source-of-founds"
          element={
            <ExtraOriginOfFundsUploadPage
              onSubmit={onSubmit}
              extraDocument={sourceOfFounds}
            />
          }
        />
        <Route
          path="/company-extract"
          element={
            <ExtraCompanyExtractUploadPage
              onSubmit={onSubmit}
              extraDocument={companyExtract}
            />
          }
        />
        <Route
          path="/director-passport"
          element={
            <ExtraPassportUploadPage
              onSubmit={onSubmit}
              extraDocument={directorPassport}
            />
          }
        />
        <Route
          path="/sworn-declaration"
          element={
            <ExtraSwornDeclarationUploadPage
              onSubmit={onSubmit}
              extraDocument={swornDeclaration}
              directorName={juridicalPersonData?.director_name || ""}
              directorMemberEmail={
                juridicalPersonData?.director_member_email || ""
              }
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to="/logged/onboarding/foreign/extra-documents"
              replace={true}
            />
          }
        />
      </Routes>
    </>
  );
};
