import { Button, Input } from "@letsbit/milkylb";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { messages } from "./messages";
import {
  AddressDetailsFormProps,
  AddressDetailsForm as AddrForm,
} from "./types";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";

export const AddressDetailsForm: React.FC<AddressDetailsFormProps> = ({
  onSubmit,
  className,
}) => {
  const { handleSubmit, control } = useForm<AddrForm>({
    mode: "onBlur",
  });
  const { formatMessage } = useIntl();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center"
      )}
    >
      <div className="h-full w-full">
        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <Input
                placeholder={formatMessage(messages.floorPlaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name="floor"
        />
        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <Input
                placeholder={formatMessage(messages.apartmentPlaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name="apartment"
        />
        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <Input
                placeholder={formatMessage(
                  messages.adtionalReferencesPlaceholder
                )}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name="additional_info"
        />
      </div>
      <Button
        type="submit"
        style={{ width: 197 }}
        className="mt-8 w-full self-end"
      >
        <FormattedMessage {...messages.continueButton} />
      </Button>
    </form>
  );
};
