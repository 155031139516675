import { defineMessages } from "react-intl";

export const scope = "pages.OnBoardingLocal.ExtraDocumentsPage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Cargar documentación",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
  companyExtractLabel: {
    id: `${scope}.companyExtractLabel`,
    defaultMessage: "Estatuto o contrato social",
  },
  minOfParnLabel: {
    id: `${scope}.minOfParnLabel`,
    defaultMessage: "Acta{br} de asamblea",
  },
  fundsOriginLabel: {
    id: `${scope}.fundsOriginLabel`,
    defaultMessage: "Origen{br} de fondos",
  },
  swornDeclarationsLabel: {
    id: `${scope}.swornDeclarationsLabel`,
    defaultMessage: "Delcaraciones juradas",
  },
});
