import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as SuccessImage } from "assets/images/onboarding/success.svg";
import { messages } from "./messages";
import { Button } from "@letsbit/milkylb";
import { UserContext } from "providers/UserProvider";

export const SuccessContent: React.FC = () => {
  const { logout } = useContext(UserContext);

  return (
    <div className="flex h-full flex-col items-center justify-center text-center">
      <SuccessImage className="mb-11" />
      <h2 className="mb-11 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
        <FormattedMessage {...messages.title} />
      </h2>
      <p className="mb-4 text-base text-[--milkylb-color-neutral-600]">
        <FormattedMessage
          {...messages.fstSubtitle}
          values={{
            br: <br />,
          }}
        />
      </p>
      <p className="mb-11 text-base text-[--milkylb-color-neutral-600]">
        <FormattedMessage {...messages.sndSubtitle} />
      </p>
      <Button onClick={() => logout()}>
        <FormattedMessage {...messages.logOutButton} />
      </Button>
    </div>
  );
};
