import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { JpdCompanyAddressPage } from "./JpdCompanyAddressPage";
import { JuridicalPersonDataRoutesProps } from "./types";
import { JpdAddressDetailsPage } from "./JpdAddressDetailsPage";
import { JpdCompanyDataPage } from "./JpdCompanyDataPage";
import { JpdOperatoryDataPage } from "./JpdOperatoryDataPage";
import { JpdFundsOriginPage } from "./JpdFundsOriginPage";
import { JpdCompanyNamePage } from "./JpdCompanyNamePage";
import { JuridicalPersonDataForm } from "@letsbit/malcolmlb";
import { JpdConfirmPage } from "./JpdConfirmPage";

export const JuridicalPersonDataRoutes: React.FC<
  JuridicalPersonDataRoutesProps
> = ({ onSubmit: onSubmitJPD, form: jpdForm }) => {
  const navigate = useNavigate();
  const onSubmit = (url: string) => (form: object) => {
    Object.entries(form).forEach(([name, value]) =>
      jpdForm.setValue(name as keyof JuridicalPersonDataForm, value)
    );

    navigate(url);
  };
  const values = jpdForm.getValues();

  return (
    <Routes>
      <Route
        path="/company-address"
        element={
          <JpdCompanyAddressPage onSubmit={onSubmit("./address-details")} />
        }
      />
      <Route
        path="/address-details"
        element={
          <JpdAddressDetailsPage
            locationForm={{
              street_name: values.street_name,
              street_number: values.street_number,
              postcode: values.postcode,
              city: values.city,
              province: values.province,
            }}
            onSubmit={onSubmit("./company-data")}
          />
        }
      />
      <Route
        path="/company-data"
        element={<JpdCompanyDataPage onSubmit={onSubmit("./operatory-data")} />}
      />
      <Route
        path="/operatory-data"
        element={<JpdOperatoryDataPage onSubmit={onSubmit("./funds-origin")} />}
      />
      <Route
        path="/funds-origin"
        element={<JpdFundsOriginPage onSubmit={onSubmit("./confirmation")} />}
      />
      <Route
        path="/company-name"
        element={
          <JpdCompanyNamePage onSubmit={onSubmit("./company-address")} />
        }
      />
      <Route
        path="/confirmation"
        element={
          <JpdConfirmPage onSubmit={onSubmitJPD} juridicalPersonData={values} />
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to="/logged/onboarding/local/juridical-person-data/company-address"
            replace={true}
          />
        }
      />
    </Routes>
  );
};
