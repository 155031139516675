import React from "react";
import { FormattedMessage } from "react-intl";

import { messages } from "./messages";
import { ExtraSwornDeclarationUploadPageProps } from "../types";
import { BackButton } from "components/BackButton";
import { Button } from "@letsbit/milkylb";
import { ReactComponent as UploadIcon } from "assets/images/icons/upload-simple.svg";
import { ExtraDocumentLabel } from "../../../components/ExtraDocsCard";
import { SwornDeclarationUpload } from "../../../components/uploads/SwornDeclarationUpload";
import ReCaptchaProvider from "providers/ReCaptchaProvider";

export const ExtraSwornDeclarationUploadPage: React.FC<
  ExtraSwornDeclarationUploadPageProps
> = ({ extraDocument, onSubmit, directorMemberEmail, directorName }) => {
  const formId = "OnBoardingCompanyExtractUploadPage__upload";

  return (
    <ReCaptchaProvider>
      <div className="flex h-full flex-col p-10">
        <div className="mb-8 flex items-center justify-between border-b border-[--milkylb-color-secondary-200] pb-8">
          <div className="flex flex-col items-start">
            <BackButton className="mb-4" />
            <div className="flex">
              <h1 className="font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
                <FormattedMessage {...messages.title} />
              </h1>
              <ExtraDocumentLabel extraDocument={extraDocument} className="ml-8 self-center" />
            </div>
          </div>
          <div className="flex justify-center ">
            <Button className="mr-4" type="submit" form={formId}>
              <UploadIcon className="mr-2" />
              <FormattedMessage {...messages.sendButton} />
            </Button>
          </div>
        </div>
        <SwornDeclarationUpload
          directorName={directorName}
          directorMemberEmail={directorMemberEmail}
          formId={formId}
          onSubmit={onSubmit}
        />
      </div>
    </ReCaptchaProvider>
  );
};
