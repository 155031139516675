import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.RepresentativeForm";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Representante legal",
  },
  inputNameDescription: {
    id: `${scope}.inputNameDescription`,
    defaultMessage: "Nombre y apellido del representante legal",
  },
  sameEmailError: {
    id: `${scope}.sameEmailError`,
    defaultMessage: "Ingrese un mail distinto al de la cuenta empresa",
  },
  inputNamePlaceholder: {
    id: `${scope}.inputNamePlaceholder`,
    defaultMessage: "Ingresa el nombre y apellido",
  },
  inputEmailDescription: {
    id: `${scope}.inputEmailDescription`,
    defaultMessage: "Correo electrónico registrado del representante legal",
  },
  inputEmailPlaceholder: {
    id: `${scope}.inputEmailPlaceholder`,
    defaultMessage: "Ingresa el correo electrónico",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
  warning: {
    id: `${scope}.warning`,
    defaultMessage:
      "¡Atención! Para continuar necesitarás contar con un cuenta de Let’sBit creada para un representante legal. Puedes bajar la app desde {href}",
  },
  href: {
    id: `${scope}.href`,
    defaultMessage: "aquí",
  },
});
