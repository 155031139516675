import { setupIonicReact } from "@letsbit/milkylb";
import { lazy, Suspense, useContext } from "react";
import { Route, Routes } from "react-router-dom";

import LoadingAnimation from "./components/LoadingAnimation";
import { LoggedRoutes } from "./pages/LoggedRoutes";
import { LocationContext } from "./providers/LocationProvider";
import { FadeInRouter } from "components/FadeInRouter";

setupIonicReact();

const AccountsRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/AccountsRoutes")).AccountsRoutes,
}));
const LogInRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/LogInRoutes")).LogInRoutes,
}));
const Auth0Routes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/Auth0Routes")).Auth0Routes,
}));
const SignUpRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/SignUpRoutes")).SignUpRoutes,
}));
const DefaultRoutes = lazy(async () => ({
  default: (await import("./pages/PublicRoutes/DefaultRoutes")).DefaultRoutes,
}));

function App() {
  const { displayLocation } = useContext(LocationContext);

  return (
    <Suspense fallback={<LoadingAnimation />}>
      <Routes location={displayLocation}>
        <Route
          path="/accounts/*"
          element={
            <FadeInRouter
              className="flex h-full w-full items-center justify-center"
              prefix="/accounts"
            >
              <AccountsRoutes />
            </FadeInRouter>
          }
        />
        <Route
          path="/signup/*"
          element={
            <FadeInRouter
              className="flex h-full w-full items-center justify-center"
              prefix="/signup"
            >
              <SignUpRoutes />
            </FadeInRouter>
          }
        />
        <Route path="/login/*" element={<LogInRoutes />} />
        <Route path="/auth0/*" element={<Auth0Routes />} />
        <Route path="/logged/*" element={<LoggedRoutes prefix="/logged" />} />
        <Route path="*" element={<DefaultRoutes />} />
      </Routes>
    </Suspense>
  );
}

export default App;
