import { defineMessages } from "react-intl";

export const scope = "utils.formMessages";

export const formMessages = defineMessages({
  requiredFieldError: {
    id: `${scope}.requiredFieldError`,
    defaultMessage: "Campo obligatorio",
  },
  emailFieldError: {
    id: `${scope}.emailFieldError`,
    defaultMessage: "Email no válido",
  },
});
