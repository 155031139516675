import React from "react";
import { FormattedMessage } from "react-intl";

import { messages } from "./messages";
import { JpdConfirmPageProps } from "../types";
import dayjs from "dayjs";
import { FormatedValue } from "components/FormatedValue";
import { Button } from "@letsbit/milkylb";
import { LogOutButton } from "components/LogOutButton";
import { BackButton } from "components/BackButton";

export const JpdConfirmPage: React.FC<JpdConfirmPageProps> = ({
  onSubmit,
  juridicalPersonData,
}) => {

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center">
      <BackButton className="absolute left-[40px] top-[24px]" />

      <LogOutButton className="absolute right-[40px] top-[24px]" />
      <div className="flex h-full w-full flex-col justify-between px-10 py-[5%]">
        <div>
          <h1 className="mb-8 w-full font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-neutral-600]">
            <FormattedMessage {...messages.title} />
          </h1>
          <ul className="max-w-xl text-right">
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.companyLabel} />
              </label>
              <div className="py-4 text-[--milkylb-color-neutral-400]">
                {juridicalPersonData.company_name}
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.addressLabel} />
              </label>
              <div className="py-4 text-[--milkylb-color-neutral-400]">
                {juridicalPersonData.street_name}{" "}
                {juridicalPersonData.street_number}{" "}
                {juridicalPersonData.floor || ""}
                {juridicalPersonData.apartment || ""}
                <br />
                {juridicalPersonData.city} {juridicalPersonData.province}
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.dateLabel} />
              </label>
              <div className="py-4 text-[--milkylb-color-neutral-400]">
                {dayjs(juridicalPersonData.registration_date).format(
                  "DD/MM/YYYY"
                )}
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.idNumberLabel} />
              </label>
              <div className="py-4 text-[--milkylb-color-neutral-400]">
                {juridicalPersonData.registration_number}
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.objectiveLabel} />
              </label>
              <div className="py-4 text-[--milkylb-color-neutral-400]">
                {juridicalPersonData.purpose}
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.amountLabel} />
              </label>
              <div className="py-4 text-[--milkylb-color-neutral-400]">
                <FormatedValue
                  value={juridicalPersonData.estimated_amount_monthly}
                  precision={2}
                />{" "}
                USD
                <br />
                <FormattedMessage {...messages.monthlyLabel} />
              </div>
            </li>
            <li className="flex items-center justify-between">
              <label className="font-bold text-[--milkylb-color-neutral-800]">
                <FormattedMessage {...messages.mailLabel} />
              </label>
              <div className="py-4 text-[--milkylb-color-neutral-400]">
                {juridicalPersonData.director_member_email}
              </div>
            </li>
          </ul>
        </div>

        <Button
          onClick={() => onSubmit()}
          style={{ width: 197 }}
          className="mt-8 w-full self-end"
        >
          <FormattedMessage {...messages.continueButton} />
        </Button>
      </div>
    </div>
  );
};
