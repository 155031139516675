import { UserWithFullInfo, LoginForm } from "@letsbit/malcolmlb";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { createVoidContext } from "utils/voidContext";
import { ApiContext } from "./ApiProvider";
import { useQueryParams } from "hooks/utils/useQueryParams";
import { useLocalStorage } from "hooks/utils/useLocalStorage";
import { DefaultError, onErrorHandler } from "utils/defaultError";
import { LocaleContext } from "./LocaleProvider";
// import { useError } from "hooks/utils/useError";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";

export interface UserData {
  country_residence?: string;
  preferred_currency?: string;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  referrer?: string;
  hdyhau?: string;
}

export interface UTMParams {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
}

export enum USER_ACCOUNT_STATUS {
  PENDING = "pending",
  ACTIVE = "active",
  BANNED = "banned",
}

export type User = Omit<UserWithFullInfo, "data"> & { data: UserData };

type UserContext = {
  user?: User | null;
  auth0LogIn: (params: {
    otp_code?: string | undefined;
    redirectPath: string;
    onErrorCallback?: ((error: unknown) => void) | undefined;
    signUpRedirect?: string | undefined;
  }) => Promise<void>;
  UTMParams: UTMParams;
  getUser: (redirectPath?: string) => Promise<void>;
  loading: boolean;
  logout: (redirectPath?: string) => Promise<void>;
  login: (form: LoginForm, redirectPath: string) => Promise<void>;
  auth0isAuthenticated: boolean;
  updateUserData: (userData: UserData) => Promise<void>;
};

export const UserContext = createContext<UserContext>(
  createVoidContext("user")
);

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, logout: auth0LO, getIdTokenClaims } = useAuth0();
  const { update: intercomUpdate, shutdown } = useIntercom();
  const { publicInstance, memberInstance } = useContext(ApiContext);
  // const { addError } = useError();
  const { showNotification } = useNotification();

  const [user, setUser] = useState<User | undefined | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const { restoreIPValidLocale } = useContext(LocaleContext);

  const { clear: clearLocalStorage } = useLocalStorage();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectPathRef = useRef<string>(
    `${location.pathname}${location.search}`
  );

  // TODO: Check with Emma how we will manage error if we are going to have the dirty modal
  // const { addError, addPurpleError, removePurpleError } = useAPIError();

  // TODO: Check new methods for notifications
  // const { enqueueSnackbar } = useSnackbar();

  const query = useQueryParams();
  const UTMParams = useMemo(
    () => ({
      utm_source: query.get("utm_source"),
      utm_medium: query.get("utm_medium"),
      utm_campaign: query.get("utm_campaign"),
    }),
    [query]
  );

  // useEffect(() => {
  //   const isUserPending = userState === "pending";
  //   if (uid && !isUserPending) {
  //     navigate(redirectPathRef.current);
  //   }
  // }, [uid, userState]);

  const logout = useCallback(
    async (redirectPath = "/login") => {
      try {
        await memberInstance.barong.logout();

        sessionStorage.clear();
        setUser(null);
        shutdown();
        clearLocalStorage();

        if (isAuthenticated) {
          auth0LO({
            async openUrl() {
              navigate(redirectPath);
            },
          });
        } else {
          navigate(redirectPath);
        }

        restoreIPValidLocale();
      } catch (error) {
        console.error(error);
        // addError(
        //   "Oups! Algo salió mal, intenta de nuevo mas tarde",
        //   "FAILED_USER_LOGOUT"
        // );
      }
    },
    [
      memberInstance.barong,
      shutdown,
      clearLocalStorage,
      isAuthenticated,
      restoreIPValidLocale,
      auth0LO,
      navigate,
    ]
  );

  const parseUser = (
    userResponse: UserWithFullInfo
  ): Omit<UserWithFullInfo, "data"> & { data: UserData } => {
    const parsedData: UserData | null = JSON.parse(userResponse.data);
    const defaultedData = {
      ...parsedData,
      country_residence:
        parsedData && "country_residence" in parsedData
          ? parsedData.country_residence
          : "",
      preferred_currency:
        parsedData && "preferred_currency" in parsedData
          ? parsedData.preferred_currency
          : "pax",
    };
    return { ...userResponse, data: defaultedData };
  };

  const redirectToOnboarding = useCallback(
    (userResponse: Omit<UserWithFullInfo, "data"> & { data: UserData }) => {
      if (
        userResponse.data.country_residence &&
        window.env.localEnterpriseCompanies.includes(
          userResponse.data.country_residence.toLowerCase()
        )
      ) {
        navigate("/logged/onboarding/local");
      } else {
        navigate("/logged/onboarding/foreign");
      }
    },
    [navigate]
  );
  const onSuccesAuth = useCallback(
    (user: UserWithFullInfo, redirectPath?: string) => {
      if (user.state === USER_ACCOUNT_STATUS.PENDING) {
        // addError({
        //   title: "Tu cuenta no está activada",
        //   message: "Te enviaremos un mail para que la actives.",
        //   secondaryButton: "CERRAR",
        // });
        showNotification({
          title: "Tu cuenta no está activada",
          message: "Te enviaremos un mail para que la actives.",
          status: NotificationStatus.WARNING,
        });

        logout(`/signup/success?email=${encodeURIComponent(user.email)}`);
      } else if (user.state === USER_ACCOUNT_STATUS.BANNED) {
        // addError({
        //   title: "Tu cuenta está baneada",
        //   message: "Contactanos al chat",
        //   secondaryButton: "CERRAR",
        // });
        showNotification({
          title: "Tu cuenta está baneada",
          message: "Contactanos al chat",
          status: NotificationStatus.WARNING,
        });

        logout();
      } else {
        const parsedUser = parseUser(user);
        setUser(parsedUser);

        intercomUpdate({
          email: user.email,
          userId: user.uid,
          // TODO: check this
          // name: naturalPersonData?.first_name || "",
          // country: naturalPersonData?.national_id,
        });

        if (user.level < 3) {
          redirectToOnboarding(parsedUser);
        } else {
          navigate(redirectPath || redirectPathRef.current, {
            replace: true,
          });
        }
      }
    },
    [logout, navigate, redirectToOnboarding, showNotification, intercomUpdate]
  );

  const getUser = useCallback(
    async (redirectPath?: string) => {
      setLoading(true);
      try {
        const { data } = await memberInstance.barong.getUser();

        onSuccesAuth(data, redirectPath);

        // TODO: Check if we stil want to use Sentry as a logging provider for frontend. Check options
        // SentrySetUser({ id: userResponse.uid });
      } catch (error) {
        navigate("/login");
        setUser(null);
        console.error(error);
      }
      setLoading(false);
    },
    [memberInstance.barong, navigate, onSuccesAuth]
  );

  const login = useCallback(
    async (form: LoginForm, redirectPath: string): Promise<void> => {
      const onSucess = (user: UserWithFullInfo): void => {
        onSuccesAuth(user, redirectPath);

        // SentrySetUser({ id: user.uid });

        // TODO: This probably will blowout with the integration of mixpanel
        // TagManager.dataLayer({ dataLayer: { event: "user_logged" } });
        // TagManager.dataLayer({
        //   dataLayer: { event: "userIdSet", userId: user.uid },
        // });
      };

      const onError = onErrorHandler((message) => {
        switch (message) {
          case "identity.session.invalid_params":
          case "identity.session.invalid_login_params":
            showNotification({
              title: "Datos incorrectos",
              message: "Mail y/o contraseña incorrectos.",
              status: NotificationStatus.ERROR,
            });
            // addError({
            //   title: "Datos incorrectos",
            //   message: "Mail y/o contraseña incorrectos.",
            //   primaryButton: "CERRAR",
            // });
            break;
          case "identity.session.not_active":
            // addError({
            //   title: "Tu cuenta no está activada",
            //   message: "Contactate con soporte para resolver el problema",
            // });
            break;
          case "identity.session.invalid_otp":
            showNotification({
              title: "Datos incorrectos",
              message: "Codigo 2FA incorrecto.",
              status: NotificationStatus.ERROR,
            });
            // addError({
            //   title: "Datos incorrectos",
            //   message: "Codigo 2FA incorrecto.",
            //   primaryButton: "CERRAR",
            // });
            break;
          case "totp.error":
            // if (setError) {
            //   setError(
            //     "otp_code",
            //     { message: "Código 2FA inválido" },
            //     { shouldFocus: true }
            //   );
            // }
            break;
          case "identity.session.missing_otp":
            // setInputOtpCode && setInputOtpCode(true);
            navigate("/login/2fa", {
              state: form,
            });

            break;
          case "server.internal_error":
            // addError({
            //   message: "Nuestra página se encuentra en mantenimiento.",
            // });
            break;
          case "identity.session.banned":
            // addError({
            //   message:
            //     "La cuenta con la que intentas ingresar se encuentra suspendida. Ante cualquier consulta, no dudes en escribirnos al soporte.",
            // });
            navigate("/login/banned");
            break;
          default:
            // addError();
            break;
        }
      });

      try {
        setUser(null);

        const { data } = await publicInstance.barong.login(form);

        onSucess(data);
      } catch (error: unknown) {
        onError(error);
      }
    },
    [navigate, onSuccesAuth, publicInstance.barong, showNotification]
  );

  const auth0LogIn = useCallback(
    async (params: {
      redirectPath: string;
      otp_code?: string;
      onErrorCallback?: (error: unknown) => void;
      signUpRedirect?: string;
    }): Promise<void> => {
      const { otp_code, redirectPath, onErrorCallback, signUpRedirect } =
        params;
      const claims = await getIdTokenClaims();

      const onSucess = (user: UserWithFullInfo): void => {
        redirectPathRef.current = redirectPath || redirectPathRef.current;

        onSuccesAuth(user);
      };

      const onError = onErrorCallback
        ? onErrorCallback
        : onErrorHandler((message: string) => {
            switch (message) {
              case "identity.session.endpoint_not_enabled":
                // addError({
                //   title: "Auth0 deshabilitado",
                //   message:
                //     "Actualmente los metodos de autenticación a traves de terceros estan deshabilitados",
                //   primaryButton: "CERRAR",
                // });
                break;
              case "identity.session.missing_otp":
                navigate("/auth0/otp_code");
                return;
              case "identity.session.auth0.invalid_params":
                // addError({
                //   title: "Error con Auth0",
                //   message:
                //     "Los parametros enviados para la autenticacion a traves de Auth0 son inválidos",
                //   primaryButton: "CERRAR",
                // });
                navigate(signUpRedirect || "/signup/user/country", {
                  state: {
                    id_token: claims?.__raw,
                  },
                });
                return;
              default:
                // addError();
                break;
            }

            navigate("/login");
          });

      try {
        setUser(undefined);
        // if you need the raw id_token, you can access it
        // using the __raw property
        const id_token = claims?.__raw;

        if (!id_token) {
          throw new DefaultError(["identity.session.auth0.invalid_claims"]);
        }

        const { data } = await publicInstance.barong.loginAuth0({
          id_token,
          otp_code,
        });

        onSucess(data);
      } catch (error: unknown) {
        onError(error);
      }
    },
    [getIdTokenClaims, navigate, onSuccesAuth, publicInstance]
  );

  const updateUserData = useCallback(
    async (data: UserData) => {
      try {
        const newData = !user?.data ? data : Object.assign(user?.data, data);

        await memberInstance.barong.updateUserData(
          JSON.stringify(newData).replace(/</g, "\\u003c")
        );

        if (user) setUser({ ...user, data: newData });
      } catch (error) {
        console.error(error);
      }
    },
    [memberInstance, user]
  );

  return (
    <UserContext.Provider
      value={{
        updateUserData,
        user,
        auth0LogIn,
        UTMParams,
        getUser,
        loading,
        logout,
        login,
        auth0isAuthenticated: isAuthenticated,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
