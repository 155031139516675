import { Button, Checkbox, Error, Input } from "@letsbit/milkylb";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { messages, options } from "./messages";
import {
  OperatoryDetailsFormProps,
  OperatoryDetailsForm as OpForm,
} from "./types";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { formMessages } from "utils/formMessages";

export const OperatoryDetailsForm: React.FC<OperatoryDetailsFormProps> = ({
  onSubmit,
  className,
}) => {
  const { handleSubmit, control, watch } = useForm<
    OpForm & {
      "purpose-other": string;
    }
  >({
    mode: "onBlur",
  });
  const { formatMessage } = useIntl();

  const purpose = watch("purpose");

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({
          estimated_amount_monthly: data.estimated_amount_monthly,
          purpose:
            data.purpose === "other" ? data["purpose-other"] : data.purpose,
        })
      )}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center"
      )}
    >
      <div className="h-full w-full">
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.objectiveLabel} />
              </label>
              <div className="mt-4 grid grid-cols-2 gap-4">
                {options.map((opt) => (
                  <Checkbox
                    className="whitespace-normal"
                    checked={opt.value === value}
                    onIonChange={(e: { target: { checked: boolean } }) => {
                      if (e.target.checked) {
                        onChange(opt.value);
                      } else {
                        onChange(undefined);
                      }
                    }}
                    justify="start"
                    labelPlacement="end"
                  >
                    <label className="whitespace-normal">
                      <FormattedMessage {...opt.label} />
                    </label>
                  </Checkbox>
                ))}
              </div>
              <Error error={!!error} message={error?.message} />
            </div>
          )}
          name="purpose"
        />
        {purpose === "other" && (
          <Controller
            control={control}
            rules={{
              required: formatMessage(formMessages.requiredFieldError),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className="fadeInSimple mb-8">
                <Input
                  placeholder={formatMessage(messages.anotherPlaceholder)}
                  value={value}
                  onIonInput={onChange}
                  error={!!error}
                  message={error?.message}
                />
              </div>
            )}
            name="purpose-other"
          />
        )}
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.stimatedAmountLabel} />
              </label>
              <Input
                placeholder={formatMessage(messages.stimatedAmountPlaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                type="number"
                message={error?.message}
              />
            </div>
          )}
          name="estimated_amount_monthly"
        />
      </div>
      <Button
        type="submit"
        style={{ width: 197 }}
        className="mt-8 w-full self-end"
      >
        <FormattedMessage {...messages.continueButton} />
      </Button>
    </form>
  );
};
