import { ExtraDocsCardProps, ExtraDocumentLabelProps, Icon } from "./types";
import { ReactComponent as Doc } from "assets/images/onboarding/doc.svg";
import { ReactComponent as DocSign } from "assets/images/onboarding/doc_sign.svg";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";

const getIcon = (icon: Icon) => {
  switch (icon) {
    case "doc":
      return <Doc />;
    case "sign":
      return <DocSign />;
  }
};

export const ExtraDocumentLabel: React.FC<ExtraDocumentLabelProps> = ({
  extraDocument,
  className,
}) => {
  if (!extraDocument) {
    return null;
  }

  const defaultClassName = "rounded-2xl px-3 py-1 text-sm";

  switch (extraDocument.status) {
    case "verified":
      return (
        <label
          className={classNames(
            className,
            defaultClassName,
            "border border-[--milkylb-color-green-success] text-[--milkylb-color-green-success]"
          )}
        >
          <FormattedMessage {...messages.aprovedLabel} />
        </label>
      );
    case "rejected":
      return (
        <label
          className={classNames(
            className,
            defaultClassName,
            "bg-[--milkylb-color-red-error] text-white"
          )}
        >
          <FormattedMessage {...messages.rejectedLabel} />
        </label>
      );
    default:
      return (
        <label
          className={classNames(
            className,
            defaultClassName,
            "border border-[--milkylb-color-secondary-200] text-[--milkylb-color-secondary-200]"
          )}
        >
          <FormattedMessage {...messages.uploadLabel} />
        </label>
      );
  }
};

export const ExtraDocsCard: React.FC<ExtraDocsCardProps> = ({
  icon = "doc",
  extraDocument,
  label,
  onClick,
}) => {
  return (
    <div
      className="relative flex w-48 cursor-pointer flex-col items-center justify-end rounded-3xl p-6 text-center shadow-md"
      onClick={onClick}
    >
      <ExtraDocumentLabel
        extraDocument={extraDocument}
        className="absolute right-2 top-2"
      />
      {getIcon(icon)}
      <label className="text-lg font-bold text-[--milkylb-color-neutral-600]">
        {label}
      </label>
    </div>
  );
};
