import React, { PropsWithChildren } from "react";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";
import { Error } from "@letsbit/milkylb";

import { FileDropzoneProps, KB_TO_MB_RATIO } from "./types";

export const FileDropzone: React.FC<PropsWithChildren<FileDropzoneProps>> = ({
  files,
  onChange,
  error,
  onBlur,
  className,
  acceptedExtensions,
  inputName,
  noClick,
  dropzoneRef,
  children,
}) => {
  return (
    <>
      <Dropzone
        ref={dropzoneRef}
        accept={acceptedExtensions?.reduce((acum, value) => {
          return {
            ...acum,
            [value]: [],
          };
        }, {})}
        noClick={noClick}
        onDrop={(e) => {
          if (e.length) {
            files !== undefined ? onChange([...files, ...e]) : onChange([...e]);
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <>
            <div
              {...getRootProps()}
              className={classnames(
                className,
                error &&
                  "border-[--milkylb-color-red-error] hover:border-[--milkylb-color-red-error]",
                files && files.length > 0
                  ? "block p-5"
                  : "flex items-center justify-evenly",
                !noClick && "cursor-pointer",
                "h-full rounded-[32px] border border-dashed border-[--milkylb-color-secondary-200] outline-none transition-[border-color] hover:border-[--milkylb-color-secondary-600]"
              )}
            >
              <input
                {...getInputProps()}
                name={inputName}
                onBlur={onBlur}
                multiple={true}
              />
              {files && files.length > 0
                ? files.map((f: File) => (
                    <div
                      key={f.name}
                      className="flex items-center justify-between text-[--milkylb-color-neutral-600]"
                    >
                      <p
                        className={classnames(
                          "text-xs",
                          error && "text-[--milkylb-color-red-error]"
                        )}
                      >
                        {`• ${f.name}`}
                        {` (${(f.size / KB_TO_MB_RATIO).toFixed(2)} mb)`}
                      </p>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onChange(
                            files?.filter((file) => file.name !== f.name)
                          );
                        }}
                      >
                        <CrossIcon
                          className={classnames(
                            "h-5 w-5 fill-[--milkylb-color-neutral-600]",
                            error && "fill-[--milkylb-color-red-error]"
                          )}
                        />
                      </button>
                    </div>
                  ))
                : children}
            </div>
          </>
        )}
      </Dropzone>
      <Error error={!!error} message={error} />
    </>
  );
};

export default FileDropzone;
