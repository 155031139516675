import { useContext, useState } from "react";
import { ApiContext } from "providers/ApiProvider";
import { defineMessages, useIntl } from "react-intl";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";
import { ExtraDocument, FormExtraDocumentation } from "@letsbit/malcolmlb";

export const scope = "hook.useUploadExtraDataDocument";

export const messages = defineMessages({
  defaultErrorNotificationMessage: {
    id: `${scope}.confirmErrorNotificationMessage`,
    defaultMessage: "No pudimos subir el documento, intentalo de nuevo mas tarde",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "Documentacion",
  },
});

export const extraDocHasValidState = (doc: ExtraDocument | undefined): boolean => {
  if (!doc || doc.status === "rejected") {
    return false
  }

  return true;
}

export const useUploadExtraDataDocument = () => {
  const { memberInstance } = useContext(ApiContext)
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const { formatMessage } = useIntl();

  const uploadExtraDocument = async (
    form: FormExtraDocumentation
  ): Promise<void> => {
    setLoading(true)
    try {
      await memberInstance.compliance.uploadExtraDocument({
        ...form,
        comments: form.comments || "empty",
      })

    } catch (error) {
      console.error(error);
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(messages.defaultErrorNotificationMessage),
        status: NotificationStatus.ERROR,
      })
      throw error;
    } finally {
      setLoading(false)
    }
  };

  return {
    uploadExtraDocument,
    loading,
  };
};
