import { defineMessages } from "react-intl";

export const scope = "pages.OnBoardingLocal.JpdConfirmPage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Chequea los datos",
  },
  companyLabel: {
    id: `${scope}.companyLabel`,
    defaultMessage: "Comañia",
  },
  addressLabel: {
    id: `${scope}.addressLabel`,
    defaultMessage: "Dirección",
  },
  dateLabel: {
    id: `${scope}.dateLabel`,
    defaultMessage: "Fecha de inscripcion",
  },
  idNumberLabel: {
    id: `${scope}.idNumberLabel`,
    defaultMessage: "Numero de inscripción",
  },
  objectiveLabel: {
    id: `${scope}.objectiveLabel`,
    defaultMessage: "Objetivo",
  },
  amountLabel: {
    id: `${scope}.amountLabel`,
    defaultMessage: "Monto",
  },
  monthlyLabel: {
    id: `${scope}.monthlyLabel`,
    defaultMessage: "mensuales",
  },
  mailLabel: {
    id: `${scope}.mailLabel`,
    defaultMessage: "Mail representante legal",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
});
