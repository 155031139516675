import { Button, Input } from "@letsbit/milkylb";
import React, { useContext } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ReactComponent as WarningIcon } from "assets/images/icons/warning.svg";
import classNames from "classnames";

import { messages } from "./messages";
import {
  RepresentativeFormProps,
  RepresentativeForm as RepForm,
} from "./types";
import { formMessages } from "utils/formMessages";
import { EmailSchema } from "utils/validators";
import { UserContext } from "providers/UserProvider";

export const RepresentativeForm: React.FC<RepresentativeFormProps> = ({
  onSubmit,
  className,
}) => {
  const { handleSubmit, control } = useForm<RepForm>({
    mode: "onBlur",
  });
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(
        className,
        "fadeInSimple relative flex flex-col items-center justify-center"
      )}
    >
      <div className="h-full">
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.inputNameDescription} />
              </label>
              <Input
                placeholder={formatMessage(messages.inputNamePlaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name="director_name"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
            validate: (email) => {
              if (!EmailSchema.safeParse(email).success) {
                return formatMessage(formMessages.emailFieldError);
              } else if (email === user?.email) {
                return formatMessage(messages.sameEmailError);
              }
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              <label className="text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.inputEmailDescription} />
              </label>
              <Input
                placeholder={formatMessage(messages.inputEmailPlaceholder)}
                value={value}
                onIonInput={onChange}
                error={!!error}
                message={error?.message}
              />
            </div>
          )}
          name="director_member_email"
        />
        <div
          className={
            "flex items-center text-xs font-semibold text-[--milkylb-color-neutral-400]"
          }
        >
          <WarningIcon className="shrink-0" />{" "}
          <p className="ml-2">
            <FormattedMessage
              {...messages.warning}
              values={{
                br: <br />,
                href: (
                  <span className="text-[--color-navbar-logo] underline">
                    <Link to="https://www.letsbit.io/descargar-app">
                      <FormattedMessage {...messages.href} />
                    </Link>
                  </span>
                ),
              }}
            />
          </p>
        </div>
      </div>
      <Button
        type="submit"
        style={{ width: 197 }}
        className="mt-8 w-full self-end"
      >
        <FormattedMessage {...messages.continueButton} />
      </Button>
    </form>
  );
};
