import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.AddressDetailsForm";

export const messages = defineMessages({
  floorPlaceholder: {
    id: `${scope}.floorPlaceholder`,
    defaultMessage: "Ingresa tu piso",
  },
  apartmentPlaceholder: {
    id: `${scope}.apartmentPlaceholder`,
    defaultMessage: "Ingresa tu departamento",
  },
  adtionalReferencesPlaceholder: {
    id: `${scope}.adtionalReferencesPlaceholder`,
    defaultMessage: "Ingresa referencias adicionales",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
});
